export const orders = `
  query($input: PagyInput!) {
    customersOrders (input: $input) {
        collection {
          orderedAt
          number
          paymentMethod
          humanOrderState
          paymentState
          total
          orderTypeI18n
          paymentTotal
          subtotal
          tax
          charge
          delivFee
        }
        metadata
    }
  }
`;

export const order = `
  query($number: String!) {
    customersOrder (number: $number) {
      order {
        number
        orderedAt
        total
        subtotal
        tax
        charge
        delivFee
        adjustmentAmount
        paymentTotal
        paymentMethod
        humanOrderState
        paymentState
        shippingCarrierName
        shippingSlip
        scheduledToBeShippedAt
        shippedAt
        scheduledToBeDeliveredAt
        scheduledDeliveryTime
        orderItems {
          quantity
          product {
            name
          }
          price
          total
        }
        shippingAddressCanNotUpdate
        paymentCanNotUpdateShippingAddress
        shippingAddress {
          name01
          name02
          addr01
          addr02
          kana01
          kana02
          fullAddress
          prefectureId
          prefectureName
          tel01
          tel02
          tel03
          zip01
          zip02
        }
      }
      target {
        targetSubtotal {
          text
          value
        }
        targetConsumption {
          text
          value
        }
      }
    }
  }
`;

export const shippingAddress = `
  query ($number: String!) {
    customersOrder (number: $number) {
      order{
        shippingAddress {
          id
          name01
          name02
          kana01
          kana02
          addr01
          addr02
          prefectureId
          tel01
          tel02
          tel03
          zip01
          zip02
        }
      }
    }
  }
`;

export const customersOrderReceiptRequest = `
  mutation($orderNumber: String!) {
    customersOrderReceiptRequest(orderNumber: $orderNumber) {
        message
    }
  }
`;
