<template>
  <div v-if="orderDetail">
    <nav-bar-order-detail :active="`address`" :number="props.number" />
    <order-address
      :address="orderDetail.shippingAddress"
      :number="props.number"
      :orderShippingAddressCanNotUpdate="orderDetail.shippingAddressCanNotUpdate"
      :paymentCanNotUpdateShippingAddress="orderDetail.paymentCanNotUpdateShippingAddress"
    />
  </div>
</template>

<script lang="ts" setup>
import NavBarOrderDetail from "@/components/molecules/common/layout/NavBarOrderDetail.vue";
import { useOrdersStore } from "@/stores/customer/orders";
import { computed } from "vue";
import OrderAddress from "@/components/organisms/pages/customer/orders/OrderAddress.vue";

interface Props {
  number: string;
}

const props = defineProps<Props>();

const ordersStore = useOrdersStore();

ordersStore.fetchOrder(props.number);

const orderDetail = computed(() => ordersStore.order);
</script>
