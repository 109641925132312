import { createApp } from "vue/dist/vue.esm-bundler.js";
import VueSelect from "vue-select";

import BootstrapVueNext, {
  BFormSelect,
  BFormCheckbox,
  BFormRadio,
  BFormTextarea,
} from "bootstrap-vue-next";

import "@/assets/scss/main.scss";

import Toaster from "@meforma/vue-toaster";

import { localize, setLocale } from "@vee-validate/i18n";

import { configure, defineRule } from "vee-validate";
import ja from "@vee-validate/i18n/dist/locale/ja.json";
import rules from "@vee-validate/rules";

configure({
  generateMessage: localize({ ja }),
});

setLocale("ja");

Object.keys(rules).map((key) => defineRule(key, rules[key]));

import * as yup from "yup";

yup.setLocale({
  mixed: {
    required: "必須項目です",
    notType(ref) {
      switch (ref.type) {
        case "number":
          return "整数を半角で入力してください";
        case "string":
          return "必須項目です";
      }
    },
  },
  string: {
    email: "メールアドレスが正しくありません",
    min(ref) {
      return `${ref.min}文字以上にしてください`;
    },
  },
});

// Global components
import FormValidator from "@/components/molecules/common/form/FormValidator.vue";
import Notice from "@/components/molecules/common/shared/Notice.vue";
import CustomerLayout from "@/components/shared/CustomerLayout.vue";
import Loading from "@/components/molecules/common/shared/Loading.vue";
import BFormInput from "@/components/molecules/common/shared/BInput.vue";

// Pages
import CustomerLogin from "@/pages/shop/customer/sign_in.vue";
import CustomerRegister from "@/pages/shop/customer/sign_up.vue";
import CustomerProfile from "@/pages/shop/customer/edit.vue";
import CustomerOrdersIndex from "@/pages/shop/customer/orders/index.vue";
import CustomerSubsOrdersIndex from "@/pages/shop/customer/subs_orders/index.vue";
import CustomerSubsOrdersShow from "@/pages/shop/customer/subs_orders/show.vue";
import CustomerSubsOrderShippingAddressShow from "@/pages/shop/customer/subs_orders/shipping_addresses/show.vue";
import CustomerSubsOrderPurchaseInformationShow from "@/pages/shop/customer/subs_orders/purchase_information/show.vue";
import CustomerSubsOrderOrderItemsShow from "@/pages/shop/customer/subs_orders/order_items/show.vue";
import CustomerSubsOrdersCancel from "@/pages/shop/customer/subs_orders/cancel/show.vue";

import CustomerOrderShippingAddressEdit from "@/pages/shop/customer/orders/shipping_addresses/edit.vue";
import CustomerOrderShippingAddressShow from "@/pages/shop/customer/orders/shipping_addresses/show.vue";
import CustomerOrdersShow from "@/pages/shop/customer/orders/show.vue";
import CustomerCardsIndex from "@/pages/shop/customer/cards/index.vue";
import CustomerShippingAddressesIndex from "@/pages/shop/customer/shipping_addresses/index.vue";
import CustomerShippingAddressesShow from "@/pages/shop/customer/shipping_addresses/show.vue";
import CustomerShippingAddressesNew from "@/pages/shop/customer/shipping_addresses/new.vue";
import CustomerPasswordChange from "@/pages/shop/customer/password_change.vue";
import ShopGuideList from "@/pages/shop/guide/index.vue";
import PublicCustomerTermIndex from "@/pages/info/customer_term/index.vue";
import ShopCustomerTermIndex from "@/pages/shop/customer_term/index.vue";
import PublicPrivacyIndex from "@/pages/info/privacy/index.vue";
import ShopPrivacyIndex from "@/pages/shop/privacy/index.vue";
import PublicLawInfoShow from "@/pages/law_info/index.vue";
import ShopLawInfoShow from "@/pages/shop/law_info/index.vue";
import ShopBaseInfoShow from "@/pages/shop/base_info/index.vue";
import ShopInformationShow from "@/pages/shop/information/_id/index.vue";
import ShopInformationIndex from "@/pages/shop/information/index.vue";
import ShopCustomerPasswordNew from "@/pages/shop/customer/password/new.vue";
import ShopCustomerPasswordEdit from "@/pages/shop/customer/password/edit.vue";

import ShopContactDraft from "@/pages/shop/contact/draft.vue";
import ShopContactConfirm from "@/pages/shop/contact/confirm.vue";
import ShopContactComplete from "@/pages/shop/contact/complete.vue";

import ShopInformationCategoryIndex from "@/pages/shop/information_categories/_id/index.vue";
import ShopIndex from "@/pages/shop/index.vue";
import ShopCustomer from "@/pages/shop/customer.vue";

import ShopPublicProducts from "@/pages/shop/products/index.vue";
import ShopFaqList from "@/pages/shop/faq/index.vue";
import PublicFaqIndex from "@/pages/info/faq/index.vue";

import AmazonCallback from "@/pages/callback/amazon_callback.vue";
import CheckoutResultReturnUrl from "@/pages/callback/checkout_result_return_url.vue";

import PublicBaseInfoIndex from "@/pages/info/base_info/index.vue";

export const setupEntryPoint = () => {
  const app = createApp();
  app.use(BootstrapVueNext);
  app.use(Toaster, { position: "top-right" }).provide("toast", app.config.globalProperties.$toast);

  app.component("CustomerLogin", CustomerLogin);
  app.component("CustomerRegister", CustomerRegister);
  app.component("CustomerProfile", CustomerProfile);
  app.component("CustomerOrdersIndex", CustomerOrdersIndex);
  app.component("CustomerOrdersShow", CustomerOrdersShow);
  app.component("CustomerOrderShippingAddressEdit", CustomerOrderShippingAddressEdit);
  app.component("CustomerOrderShippingAddressShow", CustomerOrderShippingAddressShow);
  app.component("CustomerCardsIndex", CustomerCardsIndex);
  app.component("CustomerShippingAddressesIndex", CustomerShippingAddressesIndex);
  app.component("CustomerShippingAddressesShow", CustomerShippingAddressesShow);
  app.component("CustomerShippingAddressesNew", CustomerShippingAddressesNew);
  app.component("CustomerPasswordChange", CustomerPasswordChange);
  app.component("PublicCustomerTermIndex", PublicCustomerTermIndex);
  app.component("ShopCustomerTermIndex", ShopCustomerTermIndex);
  app.component("PublicPrivacyIndex", PublicPrivacyIndex);
  app.component("ShopPrivacyIndex", ShopPrivacyIndex);
  app.component("ShopInformationShow", ShopInformationShow);
  app.component("ShopInformationIndex", ShopInformationIndex);
  app.component("ShopInformationCategoryIndex", ShopInformationCategoryIndex);
  app.component("VSelect", VueSelect);

  app.component("CustomerLayout", CustomerLayout);

  app.component("FormValidator", FormValidator);
  app.component("Notice", Notice);
  app.component("BFormInput", BFormInput);
  app.component("BInput", BFormInput);
  app.component("BSelect", BFormSelect);
  app.component("BCheckbox", BFormCheckbox);
  app.component("BRadio", BFormRadio);
  app.component("BTextarea", BFormTextarea);
  app.component("Loading", Loading);
  app.component("ShopGuideList", ShopGuideList);
  app.component("PublicLawInfoShow", PublicLawInfoShow);
  app.component("ShopLawInfoShow", ShopLawInfoShow);
  app.component("ShopBaseInfoShow", ShopBaseInfoShow);
  app.component("ShopCustomerPasswordNew", ShopCustomerPasswordNew);
  app.component("ShopCustomerPasswordEdit", ShopCustomerPasswordEdit);
  app.component("CustomerSubsOrdersIndex", CustomerSubsOrdersIndex);
  app.component("CustomerSubsOrdersShow", CustomerSubsOrdersShow);
  app.component(
    "CustomerSubsOrderPurchaseInformationShow",
    CustomerSubsOrderPurchaseInformationShow,
  );
  app.component("ShopContactDraft", ShopContactDraft);
  app.component("ShopContactConfirm", ShopContactConfirm);
  app.component("ShopContactComplete", ShopContactComplete);

  app.component("CustomerSubsOrderShippingAddressShow", CustomerSubsOrderShippingAddressShow);
  app.component("CustomerSubsOrderOrderItemsShow", CustomerSubsOrderOrderItemsShow);
  app.component("CustomerSubsOrdersCancel", CustomerSubsOrdersCancel);

  app.component("ShopIndex", ShopIndex);
  app.component("ShopCustomer", ShopCustomer);

  app.component("ShopPublicProducts", ShopPublicProducts);
  app.component("ShopFaqList", ShopFaqList);
  app.component("PublicFaqIndex", PublicFaqIndex);

  app.component("AmazonCallback", AmazonCallback);
  app.component("CheckoutResultReturnUrl", CheckoutResultReturnUrl);
  app.component("PublicBaseInfoIndex", PublicBaseInfoIndex);

  return app;
};
