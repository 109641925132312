<template>
  <div>
    <nav-bar-subs-order-detail :active="`order_items`" :number="props.number" />

    <b-card class="mb-3">
      <template #header>商品情報</template>
      <div class="table-responsive mt-5">
        <table
          class="table table-flush align-middle table-row-bordered table-row-solid gy-4 gx-2 gs-9 mb-0"
        >
          <thead class="fs-7 fw-bold text-uppercase">
            <tr>
              <th>商品名</th>
              <th>規格</th>
              <th>個数</th>
              <th>価格(税抜)</th>
              <!-- <th>操作</th> -->
            </tr>
          </thead>
          <tbody class="fs-6 text-gray-600">
            <tr v-for="(item, index) in subsOrder?.orderItems" :key="index">
              <td>
                {{ item.product.name }}
              </td>
              <td>
                <span v-for="(itemv, index) in item?.variant.variantOptionValues" :key="index"
                  >{{ itemv.productsOptionType.optionTypeName }}: {{ itemv.optionValueName }},
                </span>
              </td>
              <td>{{ item.quantity }}個</td>
              <td>{{ item.price }}円</td>

              <!-- <td>
                <a href=""> 詳細 </a>
              </td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </b-card>
    <!-- <b-card class="mb-3">
      <template #header>商品の追加</template>

      <div class="row justify-content-evenly">
        <div
          class="col col-md-3 text-center d-flex flex-column justify-content-between"
          v-for="(item, index) in subsOrder?.orderItems"
          :key="index"
        >
          <div class="img">
            <img class="w-100" :src="noImageUrl" alt="no image" />
          </div>
          <div class="mt-3">{{ item.product.name }}</div>
          <div class="mt-3">
            販売価格: <span class="text-danger">{{ item.variant.salesPrice }}円</span>
          </div>
          <div>
            <a href="" class="btn btn-dark mx-5 mt-2"> 追加する </a>
          </div>
        </div>
      </div>
    </b-card> -->
    <div>
      <a :href="`/shop/customer/subs_orders/`" class="btn btn-dark mt-5">戻る</a>
    </div>
  </div>
</template>

<script lang="ts" setup>
import NavBarSubsOrderDetail from "@/components/molecules/common/layout/NavBarSubsOrderDetail.vue";
import { useSubsOrdersStore } from "@/stores/customer/subsOrders";
import { computed, ref } from "vue";

interface Props {
  number: string;
}
const noImageUrl = ref("/images/no-image.png");

const props = defineProps<Props>();

const subsOrdersStore = useSubsOrdersStore();

subsOrdersStore.fetchSubsOrder(props.number);

const subsOrder = computed(() => subsOrdersStore.subsOrder);
</script>
<style lang="scss" scoped>
.table-responsive {
  th,
  td {
    white-space: nowrap;
    font-size: 12px;
  }
}
</style>
