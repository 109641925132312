<template>
  <div></div>
</template>
<script setup lang="ts">
import { onMounted } from "vue";
import { useCartStore } from "@/stores/cart";
const cartStore = useCartStore();

const paramsString = window.location.search;
const searchParams = new URLSearchParams(paramsString);
const buyerToken = searchParams.get("buyerToken");

onMounted(async () => {
  if (buyerToken) {
    await cartStore.ordersPaymentAmazonPayGetBuyerInfo(buyerToken);
    window.location.href = `/lp?u=${cartStore.currentBaseUrl}`;
  }
});
</script>
