import useGraphql from "@/composables/useGraphql";
import { defineStore, acceptHMRUpdate } from "pinia";
import * as shippingAddressesQueries from "@/graphql/queries/customers/shipping_addresses";
import { PagyInput } from "@/types/input/PagyInput";
import {
  CustomersShippingAddressType,
  CustomersShippingAddressUpdateResponseType,
  initCustomersShippingAddressType,
} from "@/types/customers/CustomersShippingAddressType";
import { CustomersShippingAddressTypePagination } from "@/types/customers/CustomersShippingAddressTypePagination";
import { SharedGeneralsSelectOptionType } from "@/types/shared/generals/SharedGeneralsSelectOptionType";
import { cloneDeep } from "lodash";
import { CommonType } from "@/types/customers/CommonType";
import * as orderQueries from "@/graphql/queries/customers/orders";
import { CustomersOrderType } from "@/types/customers/CustomersOrderType";

interface State {
  shippingAddresses: CustomersShippingAddressType[];
  metadata: any;
  pagy: PagyInput;
  prefectures: SharedGeneralsSelectOptionType[];
  shippingAddressInfo: CustomersShippingAddressType | null;
}

export const useShippingAddressesStore = defineStore({
  id: "shipping_addresses",

  state: (): State => ({
    shippingAddresses: [],
    metadata: {},
    pagy: {
      page: 1,
      perPage: 10,
      q: {},
    },
    prefectures: [],
    shippingAddressInfo: cloneDeep(initCustomersShippingAddressType),
  }),

  actions: {
    resetPagy() {
      this.pagy.page = 1;
      this.pagy.q = {};
    },

    fetchShippingAddresses() {
      useGraphql<CustomersShippingAddressTypePagination>(
        shippingAddressesQueries.shippingAddresses,
        {
          input: this.pagy,
        },
      ).then((data) => {
        this.shippingAddresses = data.collection;
        this.metadata = data.metadata;
      });
    },

    fetchShippingAddressInfo(id: number | string) {
      useGraphql<CustomersShippingAddressType>(shippingAddressesQueries.customersShippingAddress, {
        id,
      }).then((data) => {
        this.shippingAddressInfo = data;
      });
    },

    fetchOrderShippingAddressInfo(number: string) {
      useGraphql<CustomersOrderType>(orderQueries.shippingAddress, { number }).then((data: any) => {
        this.shippingAddressInfo = data.order.shippingAddress;
      });
    },

    updateShippingAddress(id: number | string) {
      useGraphql<CustomersShippingAddressUpdateResponseType>(
        shippingAddressesQueries.customersShippingAddressUpdate,
        {
          id,
          input: this.shippingAddressInfo,
        },
        { toasted: true },
      ).then((data) => {
        this.shippingAddressInfo = data.shippingAddress;
      });
    },

    updateOrderShippingAddress(number: string) {
      useGraphql<CustomersShippingAddressUpdateResponseType>(
        shippingAddressesQueries.ordersShippingAddressUpdate,
        {
          number,
          input: this.shippingAddressInfo,
        },
        { toasted: true },
      ).then((data) => {
        this.shippingAddressInfo = data.shippingAddress;
      });
    },

    deleteShippingAddress(id: number) {
      useGraphql<CommonType>(
        shippingAddressesQueries.customersShippingAddressDestroy,
        { id },
        { toasted: true },
      ).then(() => {
        this.fetchShippingAddresses();
      });
    },

    createShippingAddress() {
      return useGraphql<CommonType>(
        shippingAddressesQueries.customersShippingAddressCreate,
        { input: this.shippingAddressInfo },
        { toasted: true },
      );
    },

    initNewAddress() {
      this.shippingAddressInfo = cloneDeep(initCustomersShippingAddressType);
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useShippingAddressesStore, import.meta.hot));
}
