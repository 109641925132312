import useGraphql from "@/composables/useGraphql";
import { defineStore, acceptHMRUpdate } from "pinia";
import * as orderQueries from "@/graphql/queries/customers/orders";
import { CustomersOrderType, CustomersOrderTargetType } from "@/types/customers/CustomersOrderType";
import { CustomersOrderTypePagination } from "@/types/customers/CustomersOrderTypePagination";

import { PagyInput } from "@/types/input/PagyInput";

interface State {
  orders: CustomersOrderType[];
  metadata: any;
  pagy: PagyInput;
  order: CustomersOrderType | null;
  orderTargets: CustomersOrderTargetType[];
}

export const useOrdersStore = defineStore({
  id: "orders",

  state: (): State => ({
    orders: [],
    metadata: {},
    pagy: {
      page: 1,
      perPage: 10,
      q: {},
    },
    order: null,
    orderTargets: [],
  }),

  actions: {
    fetchOrders() {
      useGraphql<CustomersOrderTypePagination>(orderQueries.orders, { input: this.pagy }).then(
        (data) => {
          this.orders = data.collection;
          this.metadata = data.metadata;
        },
      );
    },

    fetchOrder(number: string) {
      useGraphql<CustomersOrderType>(orderQueries.order, { number }).then((data: any) => {
        this.order = data.order;
        this.orderTargets = data.target;
      });
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useOrdersStore, import.meta.hot));
}
