<template>
  <div></div>
</template>
<script setup lang="ts">
import { onMounted, computed } from "vue";
import { useCartStore } from "@/stores/cart";
import { useAuthStore } from "@/stores/auth";
import { useOrderStore } from "@/stores/order";
import { useGlobalStore } from "@/stores/global";

const paramsString = window.location.search;
const searchParams = new URLSearchParams(paramsString);
const amazonCheckoutSessionId = searchParams.get("amazonCheckoutSessionId");

const cartStore = useCartStore();

const authStore = useAuthStore();
const orderStore = useOrderStore();
const globalStore = useGlobalStore();
const { orderInfo, cart } = cartStore;

const upsellNormal = cart.urlData.upsellNormal;

const subsOrderData = computed(() => cartStore.offerData.subsOrderData);
const specifyDeliveryDate = computed(() => cartStore.offerData.specifyDeliveryDate);
const shippingCarrierId = computed(() => cartStore.offerData.shippingCarrierId);

onMounted(async () => {
  if (amazonCheckoutSessionId && cartStore.currentBaseUrl) {
    if (
      subsOrderData.value &&
      specifyDeliveryDate.value &&
      shippingCarrierId.value &&
      ["upsell", "xsell"].includes(cartStore.currentMode)
    ) {
      if (cartStore.currentMode === "upsell") {
        submitCvUpsell();
      } else if (cartStore.currentMode === "xsell") {
        submitCvXsell();
      }
    } else {
      cartStore
        .submitCart(amazonCheckoutSessionId)
        .then((data) => {
          if (data.token) authStore.setTokenAndCookie(data.token);

          orderStore.resetPageView(cartStore.currentBaseUrl);
          window.location.href = data.redirectUrl;
        })
        .catch(() => {
          //  TODOCHECK WHEN CATCH ERROR
          if (globalStore.validationErrors) {
            if (cartStore.currentMode == "upsell") {
              cartStore.cart.productsData = cartStore.preventiveProductsData;
              cartStore.cart.subsOrderData = cartStore.preventiveSubsOrderData;
              cartStore.cart.specifyDeliveryDate = cartStore.preventiveSpecifyDeliveryDate;
              cartStore.cart.urlData.productUpsellId = null;
              cartStore.cart.shippingCarrierId = cartStore.preventiveShippingCarrierId;
            }
            cartStore.cart.urlData.upsellNormal = false;
            globalStore.keepError = true;
            globalStore.keepBaseError = true;
            if (globalStore.resetData) cartStore.$reset();
            window.location.href = `/lp?u=${cartStore.currentBaseUrl}`;
          }
        });
    }
  }
});

const products = computed(() => {
  if (cartStore.offerData[cartStore.orderNumberCvConfirm]) {
    return cartStore.offerData[cartStore.orderNumberCvConfirm].map((item: any) => {
      return {
        id: item.id,
        quantity: item.quantity,
      };
    });
  }

  return [];
});
function submitCvUpsell() {
  const productId = cartStore.offerData[cartStore.orderNumberCvConfirm];

  cartStore
    .submitOfferCvUpsell(
      cartStore.orderNumberCvConfirm,
      productId,
      subsOrderData.value,
      specifyDeliveryDate.value,
      shippingCarrierId.value,
      amazonCheckoutSessionId,
    )
    .then((data: any) => {
      cartStore.$reset();
      orderStore.resetPageView(cartStore.currentBaseUrl);

      window.location.href = data.redirectUrl;
    });
}

function submitCvXsell() {
  cartStore
    .submitOfferCvXsell(
      cartStore.orderNumberCvConfirm,
      products.value,
      subsOrderData.value,
      specifyDeliveryDate.value,
      shippingCarrierId.value,
      amazonCheckoutSessionId,
    )
    .then((data: any) => {
      cartStore.$reset();
      orderStore.resetPageView(cartStore.currentBaseUrl);

      window.location.href = data.redirectUrl;
    });
}
</script>
